import { VideoInsertMode } from "openvidu-browser-v2compatibility";
import { CameraType } from "./enums";

export const ALTERNATIVE_HEIGHT_VIDEO_RESOLUTION = 1080;
export const DEFAULT_HEIGHT_VIDEO_RESOLUTION = 1440;
export const DEFAULT_WIDTH_VIDEO_RESOLUTION = 810;

export const DEFAULT_CAMERA_TYPE = CameraType.Back;
export const DEFAULT_LANDSCAPE_SCALE = 1.2;

export const PAR_URL = process.env.REACT_APP_PAR_URL;

export const LEGACY_DOMAIN = process.env.REACT_APP_LEGACY_DOMAIN;
export const ENVIRONMENT_CONTEXT = process.env.REACT_APP_ENVIRONMENT;

export const MICRO_SERVICES_HOST = process.env.REACT_APP_MICRO_SERVICES_HOST;
export const RELOAD_TIMEOUT_MS = Number(process.env.REACT_APP_RELOAD_TIMEOUT);

export const MEDIA_SERVICE_URI =
  ENVIRONMENT_CONTEXT !== "develop"
    ? `${MICRO_SERVICES_HOST}/${process.env.REACT_APP_MEDIA_SERVICE}/`
    : `${MICRO_SERVICES_HOST}:${process.env.REACT_APP_MEDIA_SERVICE}/`;

export const MANAGER_SERVICE_URI =
  process.env.REACT_APP_ENVIRONMENT !== "develop"
    ? `${MICRO_SERVICES_HOST}/${process.env.REACT_APP_MANAGER_SERVICE}/`
    : `${MICRO_SERVICES_HOST}:${process.env.REACT_APP_MANAGER_SERVICE}/`;

export const DEFAULT_DEVICE_OPTIONS = {
  resolution: `${DEFAULT_HEIGHT_VIDEO_RESOLUTION}x${DEFAULT_WIDTH_VIDEO_RESOLUTION}`,
  insertMode: VideoInsertMode.APPEND,
  audioSource: undefined,
  videoSource: undefined,
  publishAudio: true,
  publishVideo: true,
  frameRate: 30,
  mirror: false,
};

export const FIREBASE_CONFIG = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};
